.table-wrapper {
  width: 100%;
  padding-top: 20px;
}
.userTable {
  display: block;
  overflow: hidden;
  table-layout: fixed;
  border-collapse: collapse;
  box-shadow: 0px 10px 10px #ccc;
  border-radius: 10px;
  white-space: nowrap;
  width: 100em;
  max-width: 98%;
  margin: auto;
  table-layout: auto;
  overflow-x: auto;
}

.userTable thead {
  background-color: mediumturquoise;
  color: #222;
}

.userTable th,
.userTable td {
  padding: 0.8rem;
}

.userTable td {
  border-top: 0.5px solid #ddd;
  overflow: hidden;
  text-overflow: ellipsis;
}

.userTable tbody tr:hover {
  background-color: #eee;
}

.expand {
  width: 100%;
}
.expand > input {
  min-width: 20em;
}

.actions {
  display: flex;
  justify-content: space-around;
}

.actions svg {
  cursor: pointer;
}

.cancel-btn {
  color: #e10d05;
}
.fit {
  min-width: 100px;
}
.save-btn{
  color: #008010
}
.edit-btn {
  color: #1976d2
}